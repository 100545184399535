/* eslint-disable max-len */

function formatNameForURL(name) {
  return name.toLowerCase().trim().replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, ''); // to remove accents from string
}

function getLocalitySeo({ city, locality, zipcode }) {
  const localityName = locality || city;
  const completedZipcode = (zipcode.length === 2) ? `${zipcode}000` : zipcode;
  return {
    title: `Retouche ${localityName} (${completedZipcode}) : Votre Couturier à ${localityName} - Tilli`,
    description: `Retouche ${localityName} ${zipcode} : Votre Couturier à Domicile pour toutes vos Retouches ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getArrondissementSeo({ city, zipcode, arrondissement }) {
  return {
    title: `Retouche ${city} ${arrondissement} : Votre Couturier à ${city} ${arrondissement} (${zipcode}) - Tilli`,
    description: `Retouche ${city} ${arrondissement} : Votre Couturier à Domicile pour toutes vos Retouches ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getSeo(seo) {
  if (seo.arrondissement) return getArrondissementSeo(seo);
  return getLocalitySeo(seo);
}

// cSpell:ignore vetement
function getPath({ city, locality, arrondissement }) {
  const formattedCity = formatNameForURL(city);
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/retouche-vetement/${formattedCity}/${formattedCity}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    const formattedLocality = formatNameForURL(locality);
    return `/retouche-vetement/${formattedCity}/${formattedLocality}/`.toLowerCase();
  }
  return `/retouche-vetement/${formattedCity}/`.toLowerCase();
}

function formatUrlPath(path) {
  return path.toLowerCase().replace(/[&/\\#,+().:*?]/g, '').replace(/['"’_ ]/g, '-');
}

module.exports = {
  formatNameForURL,
  getLocalitySeo,
  getArrondissementSeo,
  getSeo,
  getPath,
  formatUrlPath,
};
