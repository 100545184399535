module.exports = {
  Step1: {
    url: '/reserver-votre-couturier-a-domicile/',
    component: 'src/pagesComponents/order/Step1.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },

  Step1Summary: {
    url: '/modifier-votre-commande/',
    component: 'src/pagesComponents/order/Step1Summary.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },

  Step2: {
    // cSpell:ignore etes
    url: '/quand-etes-vous-disponible/',
    component: 'src/pagesComponents/order/Step2.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },

  Step3: {
    url: '/ou-se-retrouve-t-on/',
    component: 'src/pagesComponents/order/Step3.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },

  Step4: {
    url: '/merci-d-avoir-pris-rdv-avec-tilli/',
    component: 'src/pagesComponents/order/Step4.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },

  Payment: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/enregistrer-votre-moyen-de-paiement',
    component: 'src/pagesComponents/order/containers/PaymentContainer.js',
    seo: { title: 'Enregistrer votre moyen de paiement - Tilli' },
  },

  SuccessPayment: {
    url: '/moyen-de-paiement-enregistre/',
    component: 'src/pagesComponents/order/containers/PaymentSuccessContainer.js',
    seo: { title: 'Votre moyen de paiement a bien été enregistré - Tilli' },
  },

  PayInOrder: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/regler-votre-commande',
    component: 'src/pagesComponents/order/containers/PayInContainer.js',
    seo: { title: 'Enregistrer votre moyen de paiement - Tilli' },
  },

  SuccessPayIn: {
    url: '/paiement-enregistre/',
    component: 'src/pagesComponents/order/containers/PayInSuccessContainer.js',
    seo: { title: 'Votre moyen de paiement a bien été enregistré - Tilli' },
  },

  PaymentBrand: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/enregistrer-un-moyen-de-paiement',
    component: 'src/pagesComponents/order/containers/PaymentContainerBrand.js',
    seo: { title: 'Enregistrer un moyen de paiement - Tilli' },
  },

  SuccessPaymentBrand: {
    url: '/moyen-de-paiement-marque-enregistre/',
    component: 'src/pagesComponents/order/containers/PaymentSuccessContainerBrand.js',
    seo: { title: 'Votre moyen de paiement a bien été enregistré - Tilli' },
  },

  AdditionalSlots: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/creneaux-supplementaires',
    component: 'src/pagesComponents/order/AdditionalSlotsPage.js',
    seo: { title: 'Créneaux supplémentaires pour ma commande' },
  },

  LaRubrique: {
    url: '/la-rubrique/',
    seo: {
      title: 'La Rubrique Tilli : toutes les inspirations',
      description: 'Trouvez des inspirations pour votre dressing en fonction de vos habits et de vos matières : broderies, poches, transformations de vêtements…',
      photo: '/la-rubrique.jpg',
    },
  },

  Masks: {
    url: '/masques-en-tissu/',
    component: 'src/pagesComponents/order/MasksPage.js',
    seo: {
      title: 'Masques en tissu - Tilli',
      description: 'Dès à présent, nous proposons des masques 100% coton pour équiper tout le monde avant et après le déconfinement. Vous pouvez directement prendre commande.',
    },
  },

  MasksForm: {
    url: '/masques-en-tissu-vos-coordonnees/',
    component: 'src/pagesComponents/order/MasksFormPage.js',
    seo: {
      title: 'Masques en tissu - Tilli',
      description: 'Dès à présent, nous proposons des masques 100% coton pour équiper tout le monde avant et après le déconfinement. Vous pouvez directement prendre commande.',
    },
  },

  MasksSuccess: {
    url: '/masques-en-tissu-confirmation/',
    component: 'src/pagesComponents/order/MasksSuccessPage.js',
    seo: {
      title: 'Masques en tissu - Tilli',
      description: 'Dès à présent, nous proposons des masques 100% coton pour équiper tout le monde avant et après le déconfinement. Vous pouvez directement prendre commande.',
    },
  },
};
