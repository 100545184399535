import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { pushToLayer } from '../services/googleTagManager';
import callApi from '../services/api';
import { getPartnerProductsFilters } from '../services/partnerProductFormatting';

const defaultState = {
  apiPartnerProducts: [],
  partnerProductsFilters: [],
  partnerProducts: [],
  promoCode: {
    code: '',
  },
  discounts: [],
  request: {},
  endpoint: '',
  orderId: undefined,
  customerId: undefined,
  initialize() {},
  fetchApiPartnerProducts() {},
  setUptradeContext() {},
  setPromoCode() {},
  setRequestAndEndpoint() {},
  setCustomerAndOrderId() {},
  getStateFromLocalStorage() {},
  toggleProduct() {},
  trackTotalPaid() {},
};

const UptradeContext = React.createContext(defaultState);

const expiryKey = 'uptradeContext_expiry';

const getFromLocalStorage = (key) => {
  const expiry = moment(localStorage.getItem(expiryKey));
  if (moment().add(-2, 'day').isBefore(expiry)) {
    return JSON.parse(localStorage.getItem(`uptradeContext_${key}`));
  }
  localStorage.removeItem(expiryKey);
  localStorage.removeItem(`uptradeContext_${key}`);
  return undefined;
};

const setInLocalStorage = (key, value) => {
  localStorage.setItem(expiryKey, (new Date()).toISOString());
  localStorage.setItem(`uptradeContext_${key}`, JSON.stringify(value));
};

const removeFromLocalStorage = (key) => localStorage.removeItem(`uptradeContext_${key}`);

const getInitialState = () => ({
  apiPartnerProducts: [],
  partnerProductsFilters: [
    { slug: 'material', label: 'Matière', list: [] },
    {
      slug: 'price',
      label: 'Prix',
      list: [
        { label: 'Entre 0€ et 50€', value: 50 },
        { label: 'Entre 50€ et 100€', value: 100 },
        { label: 'Entre 100€ et 200€', value: 200 },
        { label: 'Entre 200€ et 500€', value: 500 },
        { label: 'Entre 500€ et 1000€', value: 1000 },
        { label: '1000€ et +', value: Infinity },
      ],
      mustNotFill: true,
      paces: [50, 50, 100, 300, 500],
    },
    { slug: 'color', label: 'Couleur', list: [] },
    { slug: 'pattern', label: 'Motif', list: [] },
    {
      slug: 'length',
      label: 'Métrage',
      list: [
        { label: 'Entre 0m et 2m', value: 2 },
        { label: 'Entre 2m et 5m', value: 5 },
        { label: 'Entre 5m et 10m', value: 10 },
        { label: 'Entre 10m et 20m', value: 20 },
        { label: 'Entre 20m et 50m', value: 50 },
        { label: 'Entre 50m et 100m', value: 100 },
      ],
      mustNotFill: true,
      paces: [2, 3, 5, 10, 30, 50],
    },
  ],
  partnerProducts: [],
  promoCode: {
    code: '',
  },
  discounts: [],
  request: {},
  endpoint: '',
  orderId: undefined,
  customerId: undefined,
});

class UptradeProvider extends React.Component {
  constructor() {
    super();
    this.state = getInitialState();
  }

  componentDidMount() {
    const {
      partnerProducts, promoCode, discounts, request, endpoint, orderId, customerId,
    } = this.getStateFromLocalStorage();

    this.setUptradeContext(
      partnerProducts, promoCode, discounts, request, endpoint, orderId, customerId,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      partnerProducts, promoCode, discounts, request, endpoint, orderId, customerId,
    } = this.state;

    if (partnerProducts !== prevState.partnerProducts) setInLocalStorage('partnerProducts', partnerProducts);
    if (promoCode !== prevState.promoCode) setInLocalStorage('promoCode', promoCode);
    if (discounts !== prevState.discounts) setInLocalStorage('discounts', discounts);
    if (request !== prevState.request) setInLocalStorage('request', request);
    if (endpoint !== prevState.endpoint) setInLocalStorage('endpoint', endpoint);
    if (orderId !== prevState.orderId) setInLocalStorage('orderId', orderId);
    if (customerId !== prevState.customerId) setInLocalStorage('customerId', customerId);
  }

  setUptradeContext = (
    partnerProducts, promoCode, discounts, request, endpoint, orderId, customerId,
  ) => {
    this.setState({
      partnerProducts,
      promoCode,
      discounts,
      request,
      endpoint,
      orderId,
      customerId,
    });
  }

  getStateFromLocalStorage = () => {
    const {
      partnerProducts: partnerProductsState,
      promoCode: promoCodeState,
      discounts: discountsState,
      request: requestState,
      endpoint: endpointState,
      orderId: orderIdState,
      customerId: customerIdState,
    } = this.state;
    const partnerProducts = getFromLocalStorage('partnerProducts') || partnerProductsState;
    const promoCode = getFromLocalStorage('promoCode') || promoCodeState;
    const discounts = getFromLocalStorage('discounts') || discountsState;
    const request = getFromLocalStorage('request') || requestState;
    const endpoint = getFromLocalStorage('endpoint') || endpointState;
    const orderId = getFromLocalStorage('orderId') || orderIdState;
    const customerId = getFromLocalStorage('customerId') || customerIdState;

    return {
      partnerProducts,
      promoCode,
      discounts,
      request,
      endpoint,
      orderId,
      customerId,
    };
  }

  initialize = () => {
    this.setState = { ...getInitialState() };
    Object.keys(this.state).map((stateName) => removeFromLocalStorage(stateName));
  }

  fetchApiPartnerProducts = () => {
    callApi('/uptrade/getUptradeProducts')
      .then(({ uptradeProducts }) => {
        this.setState({ apiPartnerProducts: uptradeProducts || [] });
        this.setPartnerProductsFilters(uptradeProducts || []);
      })
      .catch(() => {
        this.setState({ apiPartnerProducts: [], partnerProductsFilters: [] });
      });
  }

  setPartnerProductsFilters = (partnerProducts) => {
    let { partnerProductsFilters } = getInitialState();
    partnerProductsFilters = getPartnerProductsFilters(partnerProductsFilters, partnerProducts);
    this.setState({ partnerProductsFilters });
  }

  setPromoCode = (code) => {
    const { promoCode: promoCodeState } = this.state;
    let promoCode = { ...promoCodeState };
    if (typeof code === 'string') {
      promoCode.code = code;
    } else {
      promoCode = code;
    }
    this.setState({ promoCode, discounts: [promoCode] });
  }

  setRequestAndEndpoint = (request, endpoint) => {
    this.setState({ request, endpoint });
  }

  setCustomerAndOrderId = (customerId, orderId) => {
    this.setState({ customerId, orderId });
  }

  toggleProduct = (product) => {
    const { partnerProducts } = this.state;
    let updatedProducts = partnerProducts;
    if (partnerProducts.find(({ id }) => id === product.id)) {
      updatedProducts = updatedProducts.filter((updatedProduct) => updatedProduct.id !== product.id);
    } else {
      updatedProducts.push(product);
    }
    this.setState({ partnerProducts: updatedProducts });
  }

  trackTotalPaid = (newTotalPaid) => {
    const { totalPaid } = this.state;
    if (newTotalPaid !== totalPaid) {
      this.setState({ totalPaid: newTotalPaid });
      pushToLayer({ order_amount: Math.round(newTotalPaid * 100) });
    }
  }

  render() {
    const { children } = this.props;
    const {
      apiPartnerProducts, partnerProductsFilters, partnerProducts, promoCode, discounts, request, endpoint, orderId, customerId,
    } = this.state;
    const uptradeContext = {
      apiPartnerProducts,
      partnerProductsFilters,
      customerId,
      discounts,
      endpoint,
      orderId,
      partnerProducts,
      promoCode,
      request,
      fetchApiPartnerProducts: this.fetchApiPartnerProducts,
      initialize: this.initialize,
      setCustomerAndOrderId: this.setCustomerAndOrderId,
      setPromoCode: this.setPromoCode,
      setRequestAndEndpoint: this.setRequestAndEndpoint,
      toggleProduct: this.toggleProduct,
      trackTotalPaid: this.trackTotalPaid,
    };

    return (
      <UptradeContext.Provider value={uptradeContext}>
        {children}
      </UptradeContext.Provider>
    );
  }
}

UptradeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UptradeContext;

export { UptradeProvider };
