// cSpell:ignore frequentes, ecommerce, evenements, legales, styleguide, serenite, Tilly
/* eslint-disable max-len */
module.exports = {
  HomePage: {
    url: '/',
    component: 'src/pagesComponents/home/HomePageV3.js',
  },

  HomePageTilly: {
    url: '/tilly/',
    component: 'src/pagesComponents/home/HomePageTilly.js',
    seo: {
      title: 'Tilli - Service de couturiers à domicile',
      description: 'Un service simple, de qualité et transparent : 1. La prise de RDV - 2. Le RDV avec votre Tilliste - 3. Le retour de vos vêtements',
    },
  },

  HomePageTilliUptrade: {
    url: '/tilli-uptrade/',
    component: 'src/pagesComponents/home/HomePageTilliUptrade.js',
    seo: {
      title: 'Vente de Tissu en ligne grâce à notre partenariat UpTrade x Tilli',
      description: 'Besoin de tissus pour donner une seconde vie à vos vêtements ou éléments de décoration ? Découvrez notre sélection et revalorisez des stocks dormants',
    },
  },

  HowItWorksPage: {
    url: '/comment-fonctionne-tilli-le-service-de-retoucherie-a-paris-et-marseille/',
    component: 'src/pagesComponents/home/HowItWorksPage.js',
    seo: {
      title: 'Service de retouches à domicile : découvrez Tilli',
      description: 'Un service simple, de qualité et transparent : 1. La prise de RDV - 2. Le RDV avec votre Tilliste - 3. Le retour de vos vêtements',
    },
  },

  AboutPage: {
    url: '/a-propos-de-tilli/',
    component: 'src/pagesComponents/home/AboutPage.js',
    seo: {
      title: 'A propos : découvrez l’histoire derrière Tilli',
      description: 'Chez Tilli, nous partageons tous un objectif commun : celui de remettre au goût du jour le métier de couturier en le digitalisant. Le couturier échange directement avec le client pour ne pas perdre en qualité et laisser place à une expérience unique !',
    },
  },

  StyleGuide: {
    url: '/styleguide/',
    component: 'src/pagesComponents/styleGuide/StyleGuide.js',
  },

  BeTillistePage: {
    url: '/devenir-couturier/',
    component: 'src/pagesComponents/home/BeTillistePage.js',
    seo: {
      title: 'Devenir Couturier Freelance - Tilli',
      description: 'Tout savoir sur le métier de couturier Tilli. Travaillez quand vous voulez, en toute simplicité. Nous recrutons à Paris, Lille, Strasbourg, Nice, Cannes, Nantes, etc',
    },
  },

  CustomerFeedbacksPage: {
    url: '/avis-de-nos-clients/',
    component: 'src/pagesComponents/home/CustomerFeedbacksPage.js',
  },

  LegalTerms: {
    url: '/mentions-legales/',
    component: 'src/pagesComponents/home/LegalTerms.js',
    seo: { title: '📃 mentions légales 📃 - Tilli' },
  },

  Login: {
    url: '/se-connecter/',
    component: 'src/pagesComponents/myAccount/Login.js',
    seo: {
      title: 'Se connecter - Tilli',
      description: 'Se connecter à son compte Tilli. Vous pouvez aussi télécharger notre appli pour suivre vos commandes.',
    },
  },

  ForgottenPasswordRequest: {
    url: '/mot-de-passe-oublie/',
    component: 'src/pagesComponents/myAccount/ForgottenPasswordRequest.js',
    seo: { title: 'Mot de passe oublié - Tilli' },
  },

  ResetPassword: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/nouveau-mot-de-passe',
    component: 'src/pagesComponents/myAccount/ResetPasswordContainer.js',
    seo: { title: 'Nouveau mot de passe - Tilli' },
  },

  Insurance: {
    url: '/assurance-MMA-en-toute-serenite/',
    component: 'src/pagesComponents/home/Insurance.js',
    seo: {
      title: 'Tous vos vêtements assurés par MMA - Tilli',
      description: 'Toutes les commandes passées chez Tilli sont assurées par notre partenaire MMA. Tilli prend soin de ses clients et couturiers !',
    },
  },

  InsuranceTilliste: {
    url: '/assurance-MMA-tilliste/',
    component: 'src/pagesComponents/home/InsuranceTilliste.js',
    seo: {
      title: 'Tous nos tillistes sont couverts par MMA - Tilli',
      description: 'En tant que tilliste, travaillez sans crainte grâce à notre partenaire MMA. Toutes vos missions sont couvertes par MMA.',
    },
  },

  EventsPage: {
    url: '/nos-evenements/',
    component: 'src/templates/EventsTemplate.js',
    seo: {
      title: 'Nos événements - Tilli',
      photo: '/eventPage.jpg',
      description: 'Atelier broderie, transformation de robe de mariée, retouches et réparations en faveur du Green Friday, création d’accessoires sur-mesure... Pour préparer les fêtes, nos Tillistes sont sur tous les fronts et multiplient les activités en boutiques chez nos marques partenaires.',
    },
  },

  PartnersPage: {
    url: '/ils-nous-font-confiance-les-partenaires-tilli/',
    component: 'src/pagesComponents/home/PartnersPage.js',
    seo: {
      title: 'Nos partenaires - Tilli',
      description: 'Ils font confiance à Tilli pour leurs retouches : Madura, Sézane, Isabel Marant, Violette Sauvage et bien d’autres',
    },
  },

  WhyBecomePartner: {
    url: '/tilli-votre-partenaire-retouche-domicile-boutique-ecommerce/',
    component: 'src/pagesComponents/home/WhyBecomePartner.js',
    seo: {
      title: 'Nous choisir comme partenaire retouches - Tilli',
      description: 'Vous êtes une marque ou une boutique, pourquoi choisir Tilli comme partenaire retouches ? Pour améliorer l’expérience client, pour simplifier votre gestion, et plus…',
    },
  },

  FAQPage: {
    url: '/les-questions-les-plus-frequentes-tilli-retouche/',
    component: 'src/pagesComponents/home/FAQPage.js',
    seo: {
      title: 'FAQ : les questions les plus fréquentes - Tilli',
      description: 'Retouches, délai de livraison, villes couvertes ? Toutes les réponses à vos question sur le service de couturier à domicile Tilli',
    },
  },

  Invite: {
    url: '/i/',
    component: 'src/pagesComponents/home/Invite.js',
    seo: {
      title: '5€ offerts sur votre première commande Tilli',
      photo: '/photo-header-referral-code.jpg',
      description: 'Tilli - votre couturier à domicile',
    },
  },

  HomePageClacDesDoigts: {
    url: '/clac-des-doigts/',
    component: 'src/pagesComponents/home/HomePageClacDesDoigts.js',
  },

  PricingsPage: {
    url: '/nos-tarifs/',
    component: 'src/pagesComponents/home/PricingsPage.js',
    seo: {
      title: 'Prix Retouche : Tarifs des Retouche de Vêtements - Tilli',
      description: 'Tarifs & Prix des Retouches Vêtements : Découvrez tous nos prix pour la retouche de vos vêtements & Rideaux ✅Couturier 7/7 de 7h à 22h',
    },
  },

  PricingsPageFull: {
    url: '/tous-nos-tarifs/',
    component: 'src/pagesComponents/home/PricingsPageFull.js',
    seo: {
      title: 'Prix Retouche : Tarifs des Retouche de Vêtements - Tilli',
      description: 'Tarifs & Prix des Retouches Vêtements : Découvrez tous nos prix pour la retouche de vos vêtements & Rideaux ✅Couturier 7/7 de 7h à 22h',
    },
  },

  PricingsPageGL: {
    url: '/nos-tarifs-galeries-lafayette/',
    component: 'src/pagesComponents/home/PricingsPageGL.js',
    seo: {
      title: 'Prix Retouche : Tarifs des Retouche de Vêtements - Tilli',
      description: 'Tarifs & Prix des Retouches Vêtements : Découvrez tous nos prix pour la retouche de vos vêtements & Rideaux ✅Couturier 7/7 de 7h à 22h',
    },
  },

  PricingsPageWebView: {
    url: '/nos-tarifs-app/',
    component: 'src/pagesComponents/home/PricingsPageWebView.js',
  },

  OurArtisansPage: {
    url: '/nos-artisans/',
    component: 'src/pagesComponents/home/OurArtisansPage.js',
    seo: {
      title: 'Nos artisans couturiers : leurs paroles - Tilli',
      description: 'Nos Tillistes, artisans couturiers, se livrent sur leur métier et leur relation avec Tilli, notamment leurs meilleurs souvenirs de missions.',
      photo: '/tillistes-board.png',
    },
  },

  JobOfferPage: {
    url: '/rejoindre-aventure-tilli/',
    component: 'src/pagesComponents/home/JobOfferPage.js',
    seo: {
      title: 'Rejoindre l’aventure Tilli : toutes nos offres de stage et d’emploi',
      description: 'Tilli recrute ! Consultez toutes nos offres d’emploi en CDI ainsi que nos offres de stage et postulez en ligne.',
    },
  },

  TilliXFitle: {
    url: '/tilli-et-fitle/',
    component: 'src/pagesComponents/home/TilliXFitle.js',
    seo: {
      title: 'Retouche Vêtement Fitle : Tilli Partenaire Fitle – Tilli',
      description: ' Qui n’a jamais eu peur de se tromper de taille en achetant ses vêtements sur Internet ou a été déçu en essayant chez lui son vêtement ? Tilli & Fitle décident donc de réunir leurs forces pour apporter une solution complète aux plateformes e-commerce.',
    },
  },

  LookBookPage: {
    url: '/lookbook-hiver-v1-2019-2020/',
    component: 'src/pagesComponents/home/LookBookPage.js',
    seo: {
      title: 'Lookbook hiver v.1 2019-2020',
      description: 'Depuis la naissance de Tilli, notre ambition est de vous prouver que la retouche, la réparation et l’upcycling est à la fois un moyen de faire plus attention à sa consommation de vêtements et une manière de laisser parler sa créativité ! ',
    },
  },

  LookBookNewsletterPage: {
    url: '/lookbook-hiver-v1-2019-2020-newsletter/',
    component: 'src/pagesComponents/home/LookBookNewsletterPage.js',
    seo: {
      title: 'Lookbook hiver v.1 2019-2020',
      description: 'Depuis la naissance de Tilli, notre ambition est de vous prouver que la retouche, la réparation et l’upcycling est à la fois un moyen de faire plus attention à sa consommation de vêtements et une manière de laisser parler sa créativité ! ',
    },
  },

  SustainableFashionPage: {
    url: '/la-mode-durable/',
    component: 'src/pagesComponents/home/SustainableFashionPage.js',
    seo: {
      title: 'La Mode Durable - Tilli',
      description: 'Expression féminine : prendre le temps de s’amuser et de laisser parler sa créativité pour donner une seconde vie à son dressing et son intérieur !',
      photo: '/last-collab-beryl.jpg',
    },
  },

  CollabPage: {
    url: '/collab/',
    component: 'src/pagesComponents/home/CollabPage.js',
    seo: {
      title: 'Les Collabs avec nos marques partenaires - Tilli',
      description: 'Tilli s’engage pour une mode responsable et accompagne ses marques partenaires avec un service de qualité et innovant, en boutique comme en ligne. Retouches, réparations, personnalisations, upcycling : des collaborations sur-mesure portées par le savoir-faire de nos artisans.',
      photo: '/last-collab.jpg',
    },
  },

  Retail: {
    url: '/nos-boutiques/',
    component: 'src/pagesComponents/home/Retail.js',
    seo: {
      title: 'Nos Boutiques - Tilli',
      description: 'Découvrez nos 3 boutiques : rue Henry Monnier, rue Notre Dame de Lorette et au Printemps Haussmann',
      photo: '/boutique.jpg',
    },
  },

  ThanksTillisteFormPage: {
    url: '/merci-inscription-tilliste/',
    component: 'src/pagesComponents/home/ThanksTillisteFormPage.js',
    isRouteNotIndexedBySearchEngine: true,
  },
};
