const devConfig = {
  API_URL: 'http://localhost:8000/api',
  googleAnalyticsTrackingId: 'UA-84397323-4',
};

const stagingConfig = {
  API_URL: 'https://api.staging.tilli.fr/api',
  googleAnalyticsTrackingId: 'UA-84397323-4',
};

const prodConfig = {
  API_URL: 'https://api.tilli.fr/api',
  googleAnalyticsTrackingId: 'UA-84397323-1',
};

let config;
if (process.env.GATSBY_ENV === 'STAGING') {
  config = stagingConfig;
} else if (process.env.GATSBY_ENV === 'PROD') {
  config = prodConfig;
} else {
  config = devConfig;
}

// cSpell:ignore deqmjoae
const cloudinaryCloudName = 'deqmjoae1';

const { API_URL, googleAnalyticsTrackingId } = config;

export {
  API_URL,
  googleAnalyticsTrackingId,
  cloudinaryCloudName,
};
