export const getFilteredApiPartnerProducts = (apiPartnerProducts, filters, partnerProductsFilters) => apiPartnerProducts.filter((apiPartnerProduct) => {
  const mustShow = Object.entries(filters).reduce((show, [key, list]) => {
    if (!show) return false;
    if ((list || []).length === 0) return show;
    const partnerProductsFilter = partnerProductsFilters.find(({ slug }) => slug === key);
    if (partnerProductsFilter.paces) {
      return list.reduce((isInsideRange, item) => {
        const index = partnerProductsFilter.list.findIndex(({ value }) => value === item);
        if (item === Infinity) {
          return (isInsideRange || (partnerProductsFilter.list[index - 1].value <= apiPartnerProduct[key]));
        }
        return (
          isInsideRange || ((item - partnerProductsFilter.paces[index]) <= apiPartnerProduct[key] && apiPartnerProduct[key] <= item)
        );
      }, false);
    }
    return list.includes(apiPartnerProduct[key]);
  }, true);
  return mustShow;
});

export const getPartnerProductsFilters = (partnerProductsFilters, partnerProducts) => {
  partnerProducts.forEach((partnerProduct) => {
    partnerProductsFilters = partnerProductsFilters.map((partnerProductsFilter) => {
      const partnerProductValue = partnerProduct[partnerProductsFilter.slug];
      if (!partnerProductsFilter.mustNotFill && partnerProductValue) {
        const list = partnerProductsFilter.list.slice();
        if (!list.find(({ value }) => value === partnerProductValue)) {
          list.push({ value: partnerProductValue, label: partnerProductValue });
        }
        return ({ ...partnerProductsFilter, list });
      }
      return partnerProductsFilter;
    });
  });
  return partnerProductsFilters;
};

const getProduct = (filter, isFirst, productA, productB) => {
  if (filter === 'GROWING') return isFirst ? productA : productB;
  return isFirst ? productB : productA;
};

export const sortApiPartnerProducts = (apiPartnerProducts, sortFilter) => {
  if (!sortFilter) return apiPartnerProducts;
  const splittedFilter = sortFilter.split('_');

  const sortedApiPartnerProducts = apiPartnerProducts.sort((productA, productB) => {
    const firstProduct = getProduct(splittedFilter[0], true, productA, productB);
    const secondProduct = getProduct(splittedFilter[0], false, productA, productB);
    return (firstProduct[splittedFilter[1].toLowerCase()] - secondProduct[splittedFilter[1].toLowerCase()]);
  });
  return sortedApiPartnerProducts;
};
