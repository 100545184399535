import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { pushToLayer, getOrderEvents, setOrderEvents } from '../services/googleTagManager';
import { getBeginHour, getAvailableActiveSlots } from '../services/slotsFormatting';
import routesMap from '../Routes';
import getDeliveryFeeMasks from '../core/services/deliveryFeeMasks';

const defaultState = {
  clothes: [],
  masks: [],
  slots: {},
  addOrEditCloth() {},
  setSlots() {},
  trackTotalPaid() {},
  getOrderRouteAndEvent() { return {}; },
  rdv3ClicksOrder: {
    fabrics: [],
    knowHow: [],
    suitAlterations: [],
  },
};

const OrderContext = React.createContext(defaultState);

const expiryKey = 'orderContext_expiry';

const getFromLocalStorage = (key) => {
  const expiry = moment(localStorage.getItem(expiryKey));
  if (moment().add(-2, 'day').isBefore(expiry)) {
    return JSON.parse(localStorage.getItem(`orderContext_${key}`));
  }
  localStorage.removeItem(expiryKey);
  localStorage.removeItem(`orderContext_${key}`);
  return undefined;
};

const setInLocalStorage = (key, value) => {
  localStorage.setItem(expiryKey, (new Date()).toISOString());
  localStorage.setItem(`orderContext_${key}`, JSON.stringify(value));
};

const removeFromLocalStorage = (key) => localStorage.removeItem(`orderContext_${key}`);

const getInitialState = () => ({
  orderId: null,
  editingClothIndex: null,
  clothes: [],
  masks: [], // for masks-only orders
  slots: {},
  promoCode: {
    code: '',
  },
  discounts: [],
  deliveryFee: 5,
  deliveryFeeMasks: 0, // for masks-only orders
  rdv3ClicksOrder: {
    fabrics: [],
    knowHow: [],
    suitAlterations: [],
    nbMasks: 0,
  },
  nbMasks: 0, // for additional mask(s) in standard funnel order
  funnelType: 'standard',
});

class OrderProvider extends React.Component {
  constructor() {
    super();
    this.state = getInitialState();
    setOrderEvents(getFromLocalStorage('dataLayerOrderEvents'));
  }

  componentDidMount() {
    if (localStorage.getItem('landingPage') === 'Advice') {
      localStorage.removeItem('landingPage');
      localStorage.removeItem('landingPage_expiryKey');
      this.initialize();
    } else {
      const {
        orderId, promoCode, discounts, slots, deliveryFee,
        clothes, editingClothIndex, rdv3ClicksOrder, funnelType,
      } = this.getStateFromLocalStorage();

      const now = moment();
      const newSlots = getAvailableActiveSlots(now, getBeginHour(moment()), slots);

      this.setOrderContext(
        orderId, promoCode, discounts, newSlots, deliveryFee,
        clothes, editingClothIndex, rdv3ClicksOrder, funnelType,
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      orderId, promoCode, discounts, slots, deliveryFee,
      clothes, editingClothIndex, rdv3ClicksOrder, funnelType, nbMasks,
    } = this.state;

    const dataLayerOrderEvents = getOrderEvents();
    setInLocalStorage('dataLayerOrderEvents', dataLayerOrderEvents);
    if (orderId !== prevState.orderId) setInLocalStorage('orderId', orderId);
    if (promoCode !== prevState.promoCode) setInLocalStorage('promoCode', promoCode);
    if (discounts !== prevState.discounts) setInLocalStorage('discounts', discounts);
    if (slots !== prevState.slots) setInLocalStorage('slots', slots);
    if (deliveryFee !== prevState.deliveryFee) setInLocalStorage('deliveryFee', deliveryFee);
    if (clothes !== prevState.clothes) setInLocalStorage('clothes', clothes);
    if (editingClothIndex !== prevState.editingClothIndex) setInLocalStorage('editingClothIndex', editingClothIndex);
    if (rdv3ClicksOrder !== prevState.rdv3ClicksOrder) setInLocalStorage('rdv3ClicksOrder', rdv3ClicksOrder);
    if (funnelType !== prevState.funnelType) setInLocalStorage('funnelType', funnelType);
    if (nbMasks !== prevState.nbMasks) setInLocalStorage('nbMasks', nbMasks);
  }

  setOrderContext = (
    orderId, promoCode, discounts, slots, deliveryFee,
    clothes, editingClothIndex, rdv3ClicksOrder, funnelType,
  ) => {
    this.setState({
      orderId,
      promoCode,
      discounts,
      slots,
      deliveryFee,
      clothes,
      editingClothIndex,
      rdv3ClicksOrder,
      funnelType,
    });
  }

  setSlots = (slots) => {
    this.setState({ slots });
  }

  setPromoCode = (code) => {
    const { promoCode: promoCodeState } = this.state;
    let promoCode = { ...promoCodeState };
    if (typeof code === 'string') {
      promoCode.code = code;
    } else {
      promoCode = code;
    }
    this.setState({ promoCode, discounts: [promoCode] });
  }

  setOrderId = (orderId) => {
    this.setState({ orderId });
  }

  setEditingClothIndex = (editingClothIndex, callback = () => {}) => {
    this.setState({ editingClothIndex }, callback);
  }

  setRDV3ClicksOrder = (knowHow, fabrics, suitAlterations, nbMasks, isFurSelected) => {
    this.setState({
      rdv3ClicksOrder: {
        fabrics,
        knowHow,
        suitAlterations,
        nbMasks,
        isFurSelected,
      },
      funnelType: 'lite',
    });
  }

  getStateFromLocalStorage = () => {
    const {
      orderId: orderIdState,
      promoCode: promoCodeState,
      discounts: discountsState,
      slots: slotsState,
      deliveryFee: deliveryFeeState,
      clothes: clothesState,
      editingClothIndex: editingClothIndexState,
      rdv3ClicksOrder: rdv3ClicksOrderState,
      funnelType: funnelTypeState,
    } = this.state;
    const orderId = getFromLocalStorage('orderId') || orderIdState;
    const promoCode = getFromLocalStorage('promoCode') || promoCodeState;
    const discounts = getFromLocalStorage('discounts') || discountsState;
    const slots = getFromLocalStorage('slots') || slotsState;
    if (getFromLocalStorage('slots')) {
      Object.keys(slots).forEach((slot) => {
        slots[slot].date = moment(slots[slot].date);
      });
    }
    const deliveryFee = getFromLocalStorage('deliveryFee') || deliveryFeeState;
    const clothes = getFromLocalStorage('clothes') || clothesState;
    const editingClothIndex = getFromLocalStorage('editingClothIndex') || editingClothIndexState;
    const rdv3ClicksOrder = getFromLocalStorage('rdv3ClicksOrder') || rdv3ClicksOrderState;
    const funnelType = getFromLocalStorage('funnelType') || funnelTypeState;

    return {
      orderId,
      promoCode,
      discounts,
      slots,
      deliveryFee,
      clothes,
      editingClothIndex,
      rdv3ClicksOrder,
      funnelType,
    };
  }

  getOrderRouteAndEvent = () => {
    const { slots: slotsState, clothes: clothesState } = this.state;
    const slots = getFromLocalStorage('slots') || slotsState;
    const clothes = getFromLocalStorage('clothes') || clothesState;
    const dataLayerOrderEvents = getFromLocalStorage('dataLayerOrderEvents');
    if (Object.keys(slots).length && clothes.length) {
      return { restart: true, dataLayerOrderEvents, orderRoute: routesMap.Step3.url };
    }
    if (clothes.length) {
      return { restart: true, dataLayerOrderEvents, orderRoute: routesMap.Step2.url };
    }
    return { restart: false, dataLayerOrderEvents: [], orderRoute: routesMap.Step1.url };
  }

  setMasks = (masks) => {
    const maskQuantity = masks.reduce((sum, mask) => sum + mask.quantity, 0);
    const deliveryFeeMasks = getDeliveryFeeMasks(maskQuantity);
    this.setState({ masks, deliveryFeeMasks });
  }

  setNbMasks = (nbMasks) => this.setState({ nbMasks })

  trackTotalPaid = (newTotalPaid) => {
    const { totalPaid } = this.state;
    if (newTotalPaid !== totalPaid) {
      this.setState({ totalPaid: newTotalPaid });
      pushToLayer({ order_amount: Math.round(newTotalPaid * 100) });
    }
  }

  addOrEditCloth = (newCloth) => {
    const { clothes, editingClothIndex } = this.state;
    const newClothes = typeof editingClothIndex === 'number'
      ? clothes.map((cloth, index) => index === editingClothIndex ? newCloth : cloth)
      : [...clothes, newCloth];
    this.setState({ clothes: newClothes, editingClothIndex: null, funnelType: 'standard' });
  }

  deleteCloth = (clothIndex, { suitPiece = undefined, sofaPart = undefined }) => {
    const { clothes } = this.state;
    if (suitPiece) {
      const newClothes = [];
      clothes.forEach((cloth, index) => {
        if (index !== clothIndex) {
          newClothes.push(cloth);
        } else {
          const newCloth = { ...cloth };
          newCloth.suitPieces = newCloth.suitPieces.filter((piece) => piece !== suitPiece);
          newCloth.suitProblems = newCloth.suitProblems.filter((problem) => !problem.includes(`${suitPiece}_`));
          newCloth.suitItems = newCloth.suitItems.filter(({ piece }) => piece !== suitPiece);
          delete newCloth.suitPieceLinings[suitPiece];
          delete newCloth.suitPiecesEmbroideries[suitPiece];
          delete newCloth.suitPiecesUpcycling[suitPiece];
          newClothes.push(newCloth);
        }
      });
      this.setState({ clothes: newClothes });
    } else if (sofaPart) {
      const newClothes = [];
      clothes.forEach((cloth, index) => {
        if (index !== clothIndex) {
          newClothes.push(cloth);
        } else {
          const newCloth = { ...cloth };
          newCloth.sofaParts = newCloth.sofaParts.filter((part) => part !== sofaPart);
          delete newCloth.sofaPartsSubTypes[sofaPart];
          delete newCloth.sofaPartsItems[sofaPart];
          delete newCloth.sofaPartsNumber[sofaPart];
          newClothes.push(newCloth);
        }
      });
      this.setState({ clothes: newClothes });
    } else {
      this.setState({ clothes: clothes.filter((cloth, index) => index !== clothIndex) });
    }
  }

  duplicateCloth = (clothIndex, currentOrderItem, { suitPiece = undefined, sofaPart = undefined }) => {
    const { clothes } = this.state;
    const newClothes = [...clothes];
    const clothToDuplicate = currentOrderItem || newClothes[clothIndex];
    if (suitPiece) {
      const newCloth = {};
      newCloth.suitPieces = clothToDuplicate.suitPieces.filter((piece) => piece === suitPiece);
      newCloth.suitProblems = clothToDuplicate.suitProblems.filter((problem) => problem.includes(`${suitPiece}_`));
      newCloth.suitItems = clothToDuplicate.suitItems.filter(({ piece }) => piece === suitPiece);
      newCloth.suitPieceLinings = clothToDuplicate?.suitPieceLinings?.[suitPiece]
        ? { [suitPiece]: clothToDuplicate.suitPieceLinings[suitPiece] } : {};
      newCloth.suitPiecesEmbroideries = clothToDuplicate?.suitPiecesEmbroideries?.[suitPiece]
        ? { [suitPiece]: clothToDuplicate.suitPiecesEmbroideries[suitPiece] } : {};
      newCloth.suitPiecesUpcycling = clothToDuplicate?.suitPiecesUpcycling?.[suitPiece]
        ? { [suitPiece]: clothToDuplicate.suitPiecesUpcycling[suitPiece] } : {};

      newClothes.push({ ...clothToDuplicate, ...newCloth });
    } else if (sofaPart) {
      const newCloth = {};
      newCloth.sofaParts = [sofaPart];
      newCloth.sofaPartsSubTypes = { [sofaPart]: clothToDuplicate.sofaPartsSubTypes[sofaPart] };
      newCloth.sofaPartsItems = { [sofaPart]: clothToDuplicate.sofaPartsItems[sofaPart] };
      newCloth.sofaPartsNumber = { [sofaPart]: clothToDuplicate.sofaPartsNumber[sofaPart] };

      newClothes.push({ ...clothToDuplicate, ...newCloth });
    } else {
      newClothes.push(clothToDuplicate);
    }

    this.setState({ clothes: newClothes });
  }

  updateDeliveryFee = (price) => {
    this.setState({ deliveryFee: price });
  }

  initialize = () => {
    this.setState(getInitialState());
    Object.keys(this.state).map((stateName) => removeFromLocalStorage(stateName));
    removeFromLocalStorage('dataLayerOrderEvents');
  }

  render() {
    const { children } = this.props;
    const {
      orderId, promoCode, discounts, slots, deliveryFee,
      clothes, editingClothIndex, rdv3ClicksOrder, funnelType, masks, deliveryFeeMasks, nbMasks,
    } = this.state;
    const editingCloth = (typeof editingClothIndex === 'number')
      ? clothes[editingClothIndex]
      : undefined;
    const orderContext = {
      orderId,
      clothes,
      masks,
      nbMasks,
      slots,
      promoCode,
      discounts,
      deliveryFee,
      deliveryFeeMasks,
      editingCloth,
      editingClothIndex,
      rdv3ClicksOrder,
      funnelType,
      addOrEditCloth: this.addOrEditCloth,
      setSlots: this.setSlots,
      setPromoCode: this.setPromoCode,
      setOrderId: this.setOrderId,
      setEditingClothIndex: this.setEditingClothIndex,
      deleteCloth: this.deleteCloth,
      duplicateCloth: this.duplicateCloth,
      updateDeliveryFee: this.updateDeliveryFee,
      setRDV3ClicksOrder: this.setRDV3ClicksOrder,
      trackTotalPaid: this.trackTotalPaid,
      initialize: this.initialize,
      getOrderRouteAndEvent: this.getOrderRouteAndEvent,
      setMasks: this.setMasks,
      setNbMasks: this.setNbMasks,
    };

    return (
      <OrderContext.Provider value={orderContext}>
        {children}
      </OrderContext.Provider>
    );
  }
}

OrderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderContext;

export { OrderProvider };
