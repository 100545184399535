module.exports = {
  ProductsSelectorPage: {
    url: '/selection-de-tissus/',
    component: 'src/pagesComponents/uptrade/ProductsSelectorPage.js',
    seo: {
      title: 'Commander des tissus - Tilli',
      description: 'Commandez des tissus en quelques clics.',
    },
  },
  DeliveryInfosPage: {
    url: '/adresse-de-livraison/',
    component: 'src/pagesComponents/uptrade/DeliveryInfosPage.js',
    seo: {
      title: 'Commander des tissus - Tilli',
      description: 'Commandez des tissus en quelques clics.',
    },
  },
  CreditCardInfosPage: {
    url: '/renseignez-votre-carte-bancaire/',
    component: 'src/pagesComponents/uptrade/CreditCardInfosPage.js',
    seo: {
      title: 'Commander des tissus - Tilli',
      description: 'Commandez des tissus en quelques clics.',
    },
  },
  PassedOrderPage: {
    url: '/commande-validee/',
    component: 'src/pagesComponents/uptrade/PassedOrderPage.js',
    seo: {
      title: 'Commander des tissus - Tilli',
      description: 'Commandez des tissus en quelques clics.',
    },
  },
  PayInSuccessPage: {
    url: '/enregistrement-paiement/',
    component: 'src/pagesComponents/uptrade/PayInSuccessPage.js',
    seo: { title: 'Votre moyen de paiement a bien été enregistré - Tilli' },
  },
};
